import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ResendTokenService } from '../api'
import {
  Button,
  Cognisphere,
  ContentContainer,
  Heading,
  Input,
  KeyVisual,
  LineContainer,
  Link,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { useAuthentication } from '../utility'
import { siemensThemeDarkHeader } from './../themes/siemens/siemensThemeDarkHeader'
import { mq } from './../utility/breakpoint'

type States = 'init' | 'success' | 'error'

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)
  const [state, setState] = React.useState<States>('init')
  const [email, setEmail] = React.useState('')

  const {
    state: { isAuthenticated },
  } = useAuthentication()

  const { t } = useTranslation('forbidden')

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    navigate(`/${lang}/`)
  }, [isAuthenticated])

  const resendToken = async () => {
    try {
      const resposne = await ResendTokenService.resendToken(email)

      if (state !== 'success') {
        setState('success')
      }
    } catch (_) {
      if (state !== 'error') {
        setState('error')
      }
    }
  }

  return (
    <PageFrame {...params} pageName="resend-token" isTop hideMenu={true}>
      {loaded && (
        <KeyVisual extraLayer hideScrollCta>
          <Cognisphere
            visual="/assets/keyvisual.jpg"
            asImage
            style={{ height: '100%' }}
          />

          <ContentContainer fullheight>
            <StartTeaser fullheight width={50}>
              <div>
                <LineContainer className="LineContainer">
                  <Heading>{parse(t('header'))}</Heading>
                </LineContainer>
                {state === 'init' && <Paragraph>{t('copy')}</Paragraph>}

                {state === 'error' && (
                  <>
                    <Button onClick={() => window.open(t('cta.uri'))}>
                      {t('cta.label')}
                    </Button>
                    <ErrorText>{t('resendToken.error')}</ErrorText>
                  </>
                )}

                <ResendTokenLinkContainer>
                  {state === 'init' && (
                    <>
                      <Paragraph>{t('resendToken.copy')}</Paragraph>
                      <Input
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                        label={t('email')}
                      ></Input>
                      <ButtonContainer>
                        <Button onClick={resendToken}>
                          {t('resendToken.cta')}
                        </Button>
                      </ButtonContainer>
                    </>
                  )}
                  {state === 'success' && (
                    <Paragraph>{t('resendToken.success')}</Paragraph>
                  )}
                </ResendTokenLinkContainer>
              </div>
            </StartTeaser>
          </ContentContainer>
        </KeyVisual>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const Copy2Container = styled.div({
  marginTop: '1.5rem',
})

const ButtonContainer = styled.div({
  marginBottom: '1rem',

  [mq[0]]: {
    marginBottom: 0,
  },
})

const ErrorText = styled(Paragraph)(({ theme }) => ({
  color: theme.colors.error,
  marginTop: '1rem',
}))

const ResendTokenLinkContainer = styled.div({
  marginTop: '2.5rem',
  '& > p, input': { marginBottom: '1.25rem' },
})

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
